import logo from "./logo.svg";
import "./App.css";
import Main from "./Components/Main";
import AdminMain from "./Components/admin/Main";
import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import {BrowserRouter} from 'react-router-dom'
import MainRoute from './routes'

function App() {
    const [isAdmin, setIsAdmin] = useState(false);

    function switchAdmin () {
        setIsAdmin(!isAdmin)
    }

    return (
        <Box
            sx={{
                boxSizing: "border-box",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <BrowserRouter>
                <MainRoute />
            </BrowserRouter>
        </Box>
    );
}

export default App;
