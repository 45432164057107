import React, { useEffect, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import SaveUpdates from "./SaveUpdates";
import { DB, post, URLs } from "./API/API";
function About(props) {
    console.log (props.data)
    return (
        <Box sx={{ color: "red" }}>
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        width: 1,
                        minHeight: 500,
                        overflow: "hidden",
                        backgroundImage:
                            "url(https://sai-wall.com/images/about_landing.jpeg)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        position: "relative",
                    }}
                ></Box>
                <Box
                    sx={{
                        // position: "fixed",
                        position: "absolute",
                        color: "white",
                        // color: "blue",
                        fontWeight: 700,
                        fontSize: "50px",
                        top: 300,
                        left: 100,
                    }}
                >
                    <Box>ABOUT</Box>
                    <Box
                        sx={{
                            color: "white",
                            fontWeight: "100px",
                            fontSize: "50px",
                        }}
                    >
                        BAO
                    </Box>
                </Box>
            </Box>
            {/* 3 */}
            <Box
                sx={{
                    display: "flex",
                    margin: 15,
                    gap: 15,
                    justifyContent: "center",
                }}
            >
                <Box>
                    <img
                        style={{ height: "70px", width: "200px" }}
                        src="./logo.png"
                    />
                </Box>
                <Box sx={{ width: "60%" }}>
                    <Box sx={{ mb: 5 }}>
                        <Box sx={{ fontWeight: 700, fontSize: 20, mb: 1 }}>
                            Vision
                        </Box>
                        <Box sx={{ fontWeight: 300 }}>
                            <TextField
                                multiline
                                fullWidth
                                value={props.data.common.vision}
                                onChange={e => props.changeData("common", "vision", e.target.value)}
                            />
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ fontWeight: 700, fontSize: 20, mb: 1 }}>
                            Mission
                        </Box>
                        <Box sx={{ fontWeight: 300 }}>
                            <TextField
                                multiline
                                fullWidth
                                value={props.data.common.mission}
                                onChange={e => props.changeData("common", "mission", e.target.value)}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* 4 */}
            <Box>
                <Box
                    sx={{
                        backgroundColor: "skyblue",
                        width: "100%",
                        height: 300,
                        color: "white",
                        textAlign: "center",
                        fontWeight: 400,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundImage: "url(./img11.jpeg)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        gap: 9,
                    }}
                >
                    <Box
                        sx={{
                            height: "30%",
                            width: "30px",
                            borderLeft: "4px solid #fff",
                            borderTop: "4px solid #fff",
                            borderBottom: "4px solid #fff",
                        }}
                    ></Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        {
                            props.data.common?.slogan0 && (
                                <>
                                    <Box sx={{ fontSize: 30 }}>{ props.data.common.slogan0[0]}</Box>
                                    <Box sx={{ fontSize: 20 }}>{ props.data.common.slogan0[1]}</Box>
                                    <Box sx={{ fontSize: 30 }}>{ props.data.common.slogan0[2]}</Box>
                                </>
                            )
                        }
                        
                    </Box>

                    <Box
                        sx={{
                            height: "30%",
                            width: "30px",
                            borderRight: "4px solid #fff",
                            borderTop: "4px solid #fff",
                            borderBottom: "4px solid #fff",
                        }}
                    ></Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#FFD95A",
                        height: 150,
                        textAlign: "center",
                        fontSize: 50,
                        fontWeight: 700,
                        alignItems: "center",
                    }}
                >
                    <Box>WHAT WE DO</Box>
                </Box>
            </Box>
            {/*5  */}
            <Box>
                <Box sx={{ display: "flex", m: 15, gap: 15 }}>
                    <Box>
                        <Box>
                            <span style={{ fontSize: "5rem", fontWeight: 700 }}>
                                벽화
                            </span>
                            <span
                                style={{
                                    fontSize: "4rem",
                                    fontWeight: 700,
                                    marginLeft: 10,
                                }}
                            >
                                MURAL
                            </span>
                        </Box>
                        <Box
                            sx={{ mt: 3, fontSize: "1.12rem", fontWeight: 300 }}
                        >
                            <TextField
                                fullWidth
                                multiline
                                value={props.data.common.mural}
                                onChange={e => props.changeData("common", "mural", e.target.value)}
                            />
                        </Box>
                    </Box>
                    <img src="/whatwedo-01.jpg" />
                </Box>
                <Box sx={{ display: "flex", m: 15, gap: 15 }}>
                    <img src="/whatwedo-02.jpg" />
                    <Box>
                        <Box>
                            <span style={{ fontSize: "5rem", fontWeight: 700 }}>
                                스텐실
                            </span>
                            <span
                                style={{
                                    fontSize: "4rem",
                                    fontWeight: 700,
                                    marginLeft: 8,
                                }}
                            >
                                STENCIL
                            </span>
                        </Box>
                        <Box
                            sx={{ mt: 3, fontSize: "1.12rem", fontWeight: 300 }}
                        >
                            <TextField
                                multiline
                                fullWidth
                                value={props.data.common.stencil}
                                onChange={e => props.changeData("common", "stencil", e.target.value)}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: "flex", m: 15, gap: 15 }}>
                    <Box>
                        <Box>
                            <span style={{ fontSize: "5rem", fontWeight: 700 }}>
                                핸드 페인팅
                            </span>
                            <span
                                style={{
                                    fontSize: "4rem",
                                    fontWeight: 700,
                                    marginLeft: 8,
                                }}
                            >
                                HAND PAINTING
                            </span>
                        </Box>

                        <Box
                            sx={{ mt: 3, fontSize: "1.12rem", fontWeight: 300 }}
                        >
                            <TextField
                                multiline
                                fullWidth
                                value={props.data.common.handPainting}
                                onChange={e => props.changeData("common", "handPainting", e.target.value)}
                            />
                        </Box>
                    </Box>
                    <img src="/whatwedo-03.jpg" />
                </Box>
            </Box>
            <Box
                sx={{
                    backgroundColor: "#FFD95A",
                    height: 150,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 8,
                }}
            >
                <Box sx={{ fontSize: 20, mb: 3 }}>{props.data.common?.slogan1?.eng}</Box>
                <Box sx={{ fontSize: 40, fontWeight: 700 }}>
                    {props.data.common?.slogan1?.kor}
                </Box>
            </Box>

            <SaveUpdates
                title="관리자 페이지입니다"
                buttonName="수정사항 저장하기"
                onClick={() => {
                    post(URLs.SAVE_SITE_FILE, {
                        file_name:DB.SITE,
                        content: props.data
                    }, (res) => console.log (res))
                    
                }}
            />
        </Box>
    );
}

export default About;
