import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";

const imgdatas = [
    { img: "/photo/1.jpeg" },
    { img: "/photo/2.jpeg" },
    { img: "/photo/3.jpeg" },
    { img: "/photo/4.jpeg" },
    { img: "/photo/5.jpeg" },
    { img: "/photo/6.jpeg" },
    { img: "/photo/7.jpeg" },
    { img: "/photo/8.jpeg" },
    { img: "/photo/9.jpeg" },
];

const img2 = [
    { img: "/photo/11.png" },
    { img: "/photo/22.png" },
    { img: "/photo/33.png" },
    { img: "/photo/44.png" },
    { img: "/photo/55.png" },
];


export default function Intro(props) {
    const [imageIndex, setImageIndex] = useState(0);

    function movePrev() {
        if (imageIndex === 0) setImageIndex(imgdatas.length - 1);
        else setImageIndex(imageIndex - 1);
    }

    function moveNext() {
        console.log("move next", imageIndex, imgdatas.length - 1);
        setImageIndex((prev) => (prev === imgdatas.length - 1 ? 0 : prev + 1));
    }
    
    return (
        <Box>
            {/* 1 */}
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                    }}
                >
                    <img
                        style={{
                            objectFit: "cover",
                            width: "100%",
                            height: 800,
                        }}
                        src={imgdatas[imageIndex].img}
                    />
                    <Button
                        sx={{ position: "absolute", top: "50%", left: 0 }}
                        onClick={movePrev}
                    >
                        LEFT
                    </Button>
                    <Button
                        sx={{ position: "absolute", top: "50%", right: 0 }}
                        onClick={moveNext}
                    >
                        RIGHT
                    </Button>

                    <Box
                        sx={{
                            position: "absolute",
                            left: 200,
                            top: 250,
                            color: "white",
                        }}
                    >
                        <Box
                            sx={{
                                fontWeight: 700,
                                fontSize: 50,
                                paddingBottom: 4,
                            }}
                        >
                            <Box>BAO RECENT</Box>
                            <Box>WORK</Box>
                        </Box>
                        <Box>
                            <Box>BAO의 포트폴리오를 감상하세요.</Box>
                            <Button
                                sx={{
                                    color: "white",
                                    border: "1px solid white",
                                    width: "200px",
                                    p: 1.5,
                                    marginTop: 4,
                                }}
                            >
                                RECENT WORK
                                {/* 포트폴리오 보러가기 */}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* 2 */}
            <Box
                sx={{
                    backgroundColor: "#FFD95A",
                    color: "black",
                }}
            >
                <Box
                    sx={{
                        pt: 8,
                        display: "flex",
                        justifyContent: "center",
                        color: "#eee",
                        fontWeight: 700,
                        fontSize: 40,
                    }}
                >
                    BEST QUALITY INEXPENSIVE PRICE
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 3,
                        pt: 5,
                    }}
                >
                    {img2.map((im, index) => (
                        <Box key={index}>
                            <img style={{ height: 200 }} src={im.img} />
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        pb: 10,
                        px: 10,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    <Box sx={{ fontWeight: 700 }}>Vision & Mission</Box>
                    <Box sx={{ fontWeight: 300 }}>
                        {props.data?.common?.vision}
                    </Box>
                    <Box sx={{ fontWeight: 300 }}>
                        {props.data?.common?.mission}
                    </Box>
                </Box>
            </Box>
            {/* 3 */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        fontSize: "40px",
                        display: "flex",
                        justifyContent: "center",
                        pt: 10,
                        pb: 3,
                    }}
                >
                    RECENT CLIENTS
                </Box>
                <Box
                    sx={{
                        fontSize: "40px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <img
                        style={{
                            height: "800px",
                            // width: 150,
                        }}
                        src="/photo/clients.jpeg"
                    />
                </Box>
            </Box>
            {/* 4 */}
            <Box
                sx={{
                    backgroundImage: "url(/photo/touch.jpeg)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundAttachment: "fixed",
                }}
            >
                <Box
                    sx={{
                        height: 500,
                        fontSize: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Box sx={{ color: "#eee", fontWeight: 700 }}>
                        We'll do our best to draw
                    </Box>
                    <Box sx={{ color: "#eee", fontWeight: 700 }}>
                        Let's work with us
                    </Box>

                    <Button
                        sx={{
                            backgroundColor: "#FFD95A",
                            color: "black",
                            width: "180px",
                            mt: 3,
                        }}
                    >
                        CONTACT US
                    </Button>
                </Box>
            </Box>
            {/* 5 */}
            {/* <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: 30,
                        p: 5,
                    }}
                >
                    INSTAGRAM
                </Box>
                <Box sx={{ display: "flex" }}>
                    <Box
                        sx={{
                            width: 300,
                            height: 200,
                            backgroundColor: "#eee",
                        }}
                    ></Box>
                    <Box
                        sx={{
                            width: 300,
                            height: 200,
                            backgroundColor: "grey",
                        }}
                    ></Box>
                    <Box
                        sx={{
                            width: 300,
                            height: 200,
                            backgroundColor: "#eee",
                        }}
                    ></Box>
                    <Box
                        sx={{
                            width: 300,
                            height: 200,
                            backgroundColor: "grey",
                        }}
                    ></Box>
                    <Box
                        sx={{
                            width: 300,
                            height: 200,
                            backgroundColor: "#eee",
                        }}
                    ></Box>
                    <Box
                        sx={{
                            width: 300,
                            height: 200,
                            backgroundColor: "grey",
                        }}
                    ></Box>
                </Box>
            </Box> */}
        </Box>
    );
}
