
import {useRoutes} from 'react-router-dom'
import Main from "../Components/Main";
import AdminMain from "../Components/admin/Main";

export default function Router () {
    const routes = [
        { path:'/', element: <Main />},
        { path:'/admin', element: <AdminMain />},
        { path:'/ai/:id', element: <Main menu="ai" />},
    ]

    return useRoutes (routes)
}