import { Box } from "@mui/material";
import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook"; 
import FeedbackIcon from "@mui/icons-material/Feedback";

export default function Head(props) {
    return (
        <Box>
            <Box
                sx={{
                    width: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    zIndex: 999,
                    bgcolor: "white",
                }}
            >
                <Box
                    sx={{
                        width: 1,
                        display: "flex",
                        gap: 5,
                        alignItems: "center",
                        justifyContent: "center",
                        // backgroundColor: "skyblue",

                        py: 2,
                    }}
                >
                    <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => props.setMenu("about")}
                    >
                        ABOUT
                    </Box>
                    <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => props.setMenu("work")}
                    >
                        WORK
                    </Box>
                    {/* <Box sx={{ fontSize: 50 }}>BAO</Box> */}
                    <img
                        style={{
                            height: "50px",
                            // width: "150px",
                        }}
                        src="/logo.png"
                        onClick={() => props.setMenu("intro")}
                    />
                    <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => props.setMenu("ai")}
                    >
                        AI
                    </Box>
                    <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => props.setMenu("contact")}
                    >
                        CONTACT
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        position: "absolute",
                        right: 0,
                        margin: "30px",
                        gap: 1,
                    }}
                >
                    <Box>
                        <InstagramIcon sx={{ cursor: "pointer" }} />
                    </Box>
                    <Box>
                        <FacebookIcon sx={{ cursor: "pointer" }} />
                    </Box>
                    <Box>
                        <FeedbackIcon sx={{ cursor: "pointer" }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
