import { Box, SpeedDial } from "@mui/material";
import React, { useState, useEffect } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useParams } from "react-router-dom";

import Head from "./Head";
import Footer from "./Footer";
import Intro from "./Intro";
import About from "./About";
import Work from "./Work";
import AI from "./AI";
import Contact from "./Contact";
import PlaygroundSpeedDial from "./SpeedDial";
import { post, URLs, DB } from "./admin/API/API";


export default function Main(props) {
    const [menu, setMenu] = useState(props.menu || "intro");
    const [data, setData] = useState('');
    useEffect(() => {
        post(URLs.GET_SITE_FILE, DB.SITE, (res) => {
            console.log ("MAIN data from API", res)
            setData(res)
        })
    }, []);
    let { id } = useParams();

    return (
        <Box sx={{ width: 1, height: 1, boxSizing: "border-box" }}>
            {/* 1 */}
            <Head setMenu={setMenu} />
            {/* 2 */}

            <Box sx={{ width: 1, pt: "82px", boxSizing: "border-box" }}>
                {menu === "intro" ? (
                    <Intro data={data} />
                ) : menu === "about" ? (
                    <About data={data} />
                ) : menu === "work" ? (
                    <Work />
                ) : menu === "ai" ? (
                    <AI id={id}/>
                ) : (
                    <Contact data={data}/>
                )}
            </Box>

            <PlaygroundSpeedDial />
            {/* 6 */}
            <Footer data={data} switchAdmin={props.switchAdmin}/>
        </Box>
    );
}
