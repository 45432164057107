import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Switch from "@mui/material/Switch";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";

const imgSrc = ["/photo/talk"];

const actions = [
    // img만 가로길이만 주면 된다 이외에는 가로세로길이를 다 주어야 한다.
    { icon: <img width="40px" src="/photo/talk.png" />, name: "Copy" },
    { icon: <img width="40px" src="/photo/kaka.png" />, name: "Save" },
    {
        icon: (
            <Box
                sx={{
                    bgcolor: "#BF360C",
                    width: 40,
                    height: 40,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 222,
                }}
            >
                <AddIcCallIcon sx={{ color: "white" }} />
            </Box>
        ),
        name: "Print",
    },
];

export default function PlaygroundSpeedDial() {
    return (
        <Box sx={{ position: "fixed", bottom: -50, right: 30, height: 320 }}>
            <SpeedDial
                ariaLabel="SpeedDial playground example"
                icon={<SpeedDialIcon />}
                direction="up"
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        FabProps={{
                            sx: {
                                borderRadius: 0,
                                "&:hover": {
                                    bgcolor: "yellow",
                                },
                            },
                        }}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
}
