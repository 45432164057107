import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";

const imgdatas = [
    { img: "/photo/1.jpeg" },
    { img: "/photo/2.jpeg" },
    { img: "/photo/3.jpeg" },
    { img: "/photo/4.jpeg" },
    { img: "/photo/5.jpeg" },
    { img: "/photo/6.jpeg" },
    { img: "/photo/7.jpeg" },
    { img: "/photo/8.jpeg" },
    { img: "/photo/9.jpeg" },
];

const img2 = [
    { img: "/photo/11.png" },
    { img: "/photo/22.png" },
    { img: "/photo/33.png" },
    { img: "/photo/44.png" },
    { img: "/photo/55.png" },
];

const texts = {
    visionAndMission: [
        "BAO는 'BAO ART'라는 벽화 중심 경관서비스를 제공하여, 국내, 외의 벽 아트작업을 선도할 것입니다. BAO는 인테리어벽화 및 실내벽화, 실외벽화, 스텐실 뿐만 아니라 다양한 아트기법 R&D를 통해 새로운 예술의 흐름을 만들어 갈 것입니다. 벽화는 단순 담벼락 예술이라는 개념을 넘어서서, 새로운 예술품, 대중 예술로 자리매김할 것입니다. 그 선도주자에는 BAO이 있습니다.",
        "BAO는 예술의 창의성과 기술의 간결성에 주목하여 시작된 기업입니다. 청년 –주로 미대생, 신진작가- 들에게 벽화그리기 일자리를 제공하기 위해 사업을 진행하고 있습니다. 뿐만 아니라 청년들이 성장하기 위한 발판으로서 “프로타주 갤러리”를 운영 청년들의 자생력을 만들기 위해 돕고 있습니다.",
    ],
};


export default function Intro(props) {
    const [imageIndex, setImageIndex] = useState(0);

    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         moveNext();
    //         console.log("hey");
    //     }, 3000);
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);
    //타이머안에서는 이미지 인덱스가 변화하지않는다. 몇초마다한번씩 일을 해야할때 setInterval
    // setInterval자바스크립트 내장함수
    // setInterval (화살표함수, 대기시간) : 주기적으로 대기시간 후에 화살표 함수를 실행

    function movePrev() {
        if (imageIndex === 0) setImageIndex(imgdatas.length - 1);
        else setImageIndex(imageIndex - 1);
    }

    function moveNext() {
        console.log("move next", imageIndex, imgdatas.length - 1);

        // 이전 스타일 코드: setInterval에서는 동작하지 않음
        // setImageIndex (imageIndex === imgdatas.length - 1? 0 : imageIndex + 1)

        // 새로운 스타일 코드: set하는 함수 내에서 화살표 함수를 이용함. 파라미터를 받아와서 바꾸도록했다.
        setImageIndex((prev) => (prev === imgdatas.length - 1 ? 0 : prev + 1));
    }

    const introimgs = [
        { introimg1: "/photo/clients.jpeg" },
        { introimg2: "/photo/touch.jpeg" },
        { titlelogo: "https://sai-wall.com/images/logo.png" },
    ];
    return (
        <Box>
            {/* 1 */}
            <Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                    }}
                >
                    <img
                        style={{
                            objectFit: "cover",
                            width: "100%",
                            height: 800,
                        }}
                        src={imgdatas[imageIndex].img}
                    />
                    <Button
                        sx={{ position: "absolute", top: "50%", left: 0 }}
                        onClick={movePrev}
                    >
                        LEFT
                    </Button>
                    <Button
                        sx={{ position: "absolute", top: "50%", right: 0 }}
                        onClick={moveNext}
                    >
                        RIGHT
                    </Button>

                    <Box
                        sx={{
                            position: "absolute",
                            left: 200,
                            top: 250,
                            color: "white",
                        }}
                    >
                        <Box
                            sx={{
                                fontWeight: 700,
                                fontSize: 50,
                                paddingBottom: 4,
                            }}
                        >
                            <Box>BAO RECENT</Box>
                            <Box>WORK</Box>
                        </Box>
                        <Box>
                            <Box>BAO의 포트폴리오를 감상하세요.</Box>
                            <Button
                                sx={{
                                    color: "white",
                                    border: "1px solid white",
                                    width: "200px",
                                    p: 1.5,
                                    marginTop: 4,
                                }}
                            >
                                RECENT WORK
                                {/* 포트폴리오 보러가기 */}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* 2 */}
            <Box
                sx={{
                    backgroundColor: "#FFD95A",
                    color: "black",
                }}
            >
                <Box
                    sx={{
                        pt: 8,
                        display: "flex",
                        justifyContent: "center",
                        color: "#eee",
                        fontWeight: 700,
                        fontSize: 40,
                    }}
                >
                    BEST QUALITY INEXPENSIVE PRICE
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 3,
                        pt: 5,
                    }}
                >
                    {img2.map((im, index) => (
                        <Box key={index}>
                            <img style={{ height: 200 }} src={im.img} />
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        pb: 10,
                        px: 10,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    <Box sx={{ fontWeight: 700 }}>Vision & Mission</Box>
                    <Box sx={{ fontWeight: 300 }}>
                        {texts.visionAndMission[0]}
                    </Box>
                    <Box sx={{ fontWeight: 300 }}>
                        {texts.visionAndMission[1]}
                    </Box>
                </Box>
            </Box>
            {/* 3 */}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Box
                    sx={{
                        fontSize: "40px",
                        display: "flex",
                        justifyContent: "center",
                        pt: 10,
                        pb: 3,
                    }}
                >
                    RECENT CLIENTS
                </Box>
                <Box
                    sx={{
                        fontSize: "40px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <img
                        style={{
                            height: "800px",
                            // width: 150,
                        }}
                        src="/photo/clients.jpeg"
                    />
                </Box>
            </Box>
            {/* 4 */}
            <Box
                sx={{
                    backgroundImage: "url(/photo/touch.jpeg)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundAttachment: "fixed",
                }}
            >
                <Box
                    sx={{
                        height: 500,
                        fontSize: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Box sx={{ color: "#eee", fontWeight: 700 }}>
                        We'll do our best to draw
                    </Box>
                    <Box sx={{ color: "#eee", fontWeight: 700 }}>
                        Let's work with us
                    </Box>

                    <Button
                        sx={{
                            backgroundColor: "#FFD95A",
                            color: "black",
                            width: "180px",
                            mt: 3,
                        }}
                    >
                        CONTACT US
                    </Button>
                </Box>
            </Box>
            {/* 5 */}

        </Box>
    );
}
